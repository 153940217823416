
import * as Sentry from '@sentry/browser'
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { get as getProductions } from '@/api/customer/order/catalog/productions'
import { serviceFactory } from '@/service'

type Product = {
  name: string
  description: string
  url: string
  img: string
}

export default defineComponent({
  setup() {
    const { t, d } = useTranslation()
    const items = reactive<{ products: Product[] }>({ products: [] })
    const { products } = toRefs(items)
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const loadedImg = ref(0)
    const isLoaded = ref(false)
    const noProducts = ref('')
    const lazyOptions = reactive({
      lifecycle: {
        // loading: () => {},
        // error: () => {},
        loaded: () => {
          loadedImg.value++
          if (loadedImg.value === products.value.length) {
            isLoaded.value = true
          }
        },
      },
    })

    onBeforeMount(() => {
      isLoaded.value = false
      loadedImg.value = 0

      getProductions().then((res) => {
        products.value = res.map((r) => {
          const service = serviceFactory(r.serviceName, t, d)
          return service.getProduct()
        })

        if (products.value.length === 0) {
          noProducts.value = t('There is no service which you can buy')
        }
      })
    })

    const onClick = (url: string) => {
      router.push({
        path: `/${locale}/${url}`,
        query: {
          serviceName: 'qt-dns',
          paymentFrequency: 'monthly',
          amount: 1,
        },
      })
    }

    return {
      t,
      onClick,
      isLoaded,
      products,
      noProducts,
      lazyOptions,
    }
  },
})
