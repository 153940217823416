<template>
  <div class="container mx-auto md:w-8/12">
    <h1 class="text-left text-2xl text-gray-600 my-4 font-bold">
      {{ t('Service list') }}
    </h1>
    <h1 v-if="products.length === 0" class="text-left text-gray-500">
      {{ noProducts }}
    </h1>
    <div v-for="(product, index) in products" :key="index">
      <div :class="{ loadImg: isLoaded }">
        <div class="flex">
          <div>
            <img
              v-lazy="{ src: product.img, lifecycle: lazyOptions.lifecycle }"
            />
          </div>

          <div v-if="isLoaded" class="mx-4 h-full">
            <h1 class="text-2xl text-gray-600 mb-4 font-bold">
              {{ product.name }}
            </h1>
            <p class="text-base text-gray-600 w-80">
              {{ product.description }}
            </p>

            <button
              @click="onClick(product.url)"
              class="bg-blue-500 text-white px-10 py-1 mt-10 hover:bg-blue-700"
            >
              {{ t('Go to purchase') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { get as getProductions } from '@/api/customer/order/catalog/productions'
import { serviceFactory } from '@/service'

type Product = {
  name: string
  description: string
  url: string
  img: string
}

export default defineComponent({
  setup() {
    const { t, d } = useTranslation()
    const items = reactive<{ products: Product[] }>({ products: [] })
    const { products } = toRefs(items)
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const loadedImg = ref(0)
    const isLoaded = ref(false)
    const noProducts = ref('')
    const lazyOptions = reactive({
      lifecycle: {
        // loading: () => {},
        // error: () => {},
        loaded: () => {
          loadedImg.value++
          if (loadedImg.value === products.value.length) {
            isLoaded.value = true
          }
        },
      },
    })

    onBeforeMount(() => {
      isLoaded.value = false
      loadedImg.value = 0

      getProductions().then((res) => {
        products.value = res.map((r) => {
          const service = serviceFactory(r.serviceName, t, d)
          return service.getProduct()
        })

        if (products.value.length === 0) {
          noProducts.value = t('There is no service which you can buy')
        }
      })
    })

    const onClick = (url: string) => {
      router.push({
        path: `/${locale}/${url}`,
        query: {
          serviceName: 'qt-dns',
          paymentFrequency: 'monthly',
          amount: 1,
        },
      })
    }

    return {
      t,
      onClick,
      isLoaded,
      products,
      noProducts,
      lazyOptions,
    }
  },
})
</script>
<style scoped lang="postcss">
.loadImg {
  @apply text-left bg-white border border-transparent shadow-sm p-4 mb-2;
}
</style>
